/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Form,
  FormGroup,
  Label,
  InputGroup,
} from "reactstrap";
import { Link } from "react-router-dom";

// Custom Scrollbar
import SimpleBar from "simplebar-react";

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png";
import servicesIcon2 from "../../assets/images/services-icon/02.png";
import servicesIcon3 from "../../assets/images/services-icon/03.png";
import servicesIcon4 from "../../assets/images/services-icon/04.png";
import user2 from "../../assets/images/users/user-2.jpg";
import user3 from "../../assets/images/users/user-3.jpg";
import user4 from "../../assets/images/users/user-4.jpg";
import user5 from "../../assets/images/users/user-5.jpg";
import user6 from "../../assets/images/users/user-6.jpg";
import smimg1 from "../../assets/images/small/img-1.jpg";
import smimg2 from "../../assets/images/small/img-2.jpg";

// Charts
import LineAreaChart from "../AllCharts/apex/lineareachart";
import RadialChart from "../AllCharts/apex/apexdonut";
import Apexdonut from "../AllCharts/apex/apexdonut1";
import SparkLine from "../AllCharts/sparkline/sparkline";
import SparkLine1 from "../AllCharts/sparkline/sparkline1";
import Salesdonut from "../AllCharts/apex/salesdonut";
import "chartist/dist/scss/chartist.scss";

//i18n
import { withTranslation } from "react-i18next";
import axios from "axios/axios";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { format } from "date-fns";
import CountUp from "react-countup";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Grid,
} from "@mui/material";
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const Dashboard = (props) => {
  const [menu, setMenu] = useState(false);
  const [reload, setReload] = useState(false);
  const [filter, setFilter] = useState({});
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [salesData, setSalesData] = useState([]);
  const [error, setError] = useState(null);
  const [loadingStatistics, setLoadingStatistics] = useState(false);

  useEffect(() => {
    let filterStr = "";
    if (filter.start) {
      filterStr += `startDate=${filter.start}`;
    }
    if (filter.end) {
      let end = new Date(filter.end);
      end.setDate(end.getDate() + 1);
      filterStr += `${filterStr.length > 0 ? "&" : ""}endDate=${format(
        end,
        "yyyy-MM-dd"
      )}`;
    }
    filterStr += `${filterStr.length > 0 ? "&" : ""}status=delivered`;
    setOrders(0);
    axios
      .get(`orders/all${filterStr.length > 0 ? `?${filterStr}` : ""}`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setOrders(
          res.data.orders &&
            res.data.orders.reduce((acc, one) => acc + one.total, 0)
        );
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [reload]);

  // document.title = "Dashboard | Ismail Clinic"
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [product, setProduct] = useState("");
  const [products, setProducts] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    setLoadingStatistics(true);

    axios
      .get(`/products/all`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        setProducts(response?.data?.products || []);
      })
      .catch((err) => {
        setError("Failed to fetch data.");
      })
      .finally(() => {
        setLoadingStatistics(false);
      });
  }, []);
  useEffect(() => {
    setLoadingStatistics(true);

    const params = new URLSearchParams();
    if (year) params.append("year", year);
    if (month) params.append("month", month);
    if (product) params.append("productId", product);

    axios
      .get(`/admins/statistics?${params.toString()}`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        setSalesData(response?.data?.data || []);
      })
      .catch((err) => {
        setError("Failed to fetch data.");
      })
      .finally(() => {
        setLoadingStatistics(false);
      });
  }, [year, month, product]);

  const currentYear = new Date().getFullYear();
  const isLastDayOfYear =
    new Date().getMonth() === 11 && new Date().getDate() === 31;
  
  const years = isLastDayOfYear
    ? ["", currentYear, currentYear + 1]
    : ["", currentYear];
  const months = ["", ...Array.from({ length: 12 }, (_, i) => i + 1)];

  // const products = [
  //   "",
  //   ...Array.from(new Set(salesData.map((item) => item.productId))),
  // ];
  const productMap = salesData.reduce((acc, item) => {
    acc[item.productId] = item.product;
    return acc;
  }, {});
  useEffect(() => {
    let data = [...salesData];

    const aggregatedData = data
      .reduce((acc, curr) => {
        const productName = curr.product;
        const existing = acc.find((item) => item.product === productName);
        if (existing) {
          existing.totalViewsCount += curr.totalViewsCount;
          existing.totalCartCount += curr.totalCartCount;
        } else {
          acc.push({
            product: productName,
            totalViewsCount: curr.totalViewsCount,
            totalCartCount: curr.totalCartCount,
          });
        }
        return acc;
      }, [])
      .map((item) => ({
        ...item,
        conversionRate:
          item.totalViewsCount > 0
            ? ((item.totalCartCount / item.totalViewsCount) * 100).toFixed(2)
            : 0,
      }));

    setFilteredData(aggregatedData);
  }, [salesData]);

  // if (loadingStatistics) {
  //   return (
  //     <Box sx={{ padding: 4, textAlign: "center" }}>
  //       <Typography variant="h6">Loading...</Typography>
  //     </Box>
  //   );
  // }

  if (error) {
    return (
      <Box sx={{ padding: 4, textAlign: "center" }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
              </Col>
            </Row>
          </div>
          <Form>
            <Row>
              <Col md={6} xs={12}>
                <FormGroup className="mb-4">
                  <Label>Start</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="Start"
                      value={filter.start}
                      onChange={(dates) => {
                        setFilter({
                          ...filter,
                          start: format(new Date(dates[0]), "yyyy-MM-dd"),
                        });
                        if (filter.end) {
                          setReload(!reload);
                        }
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={6} xs={12}>
                <FormGroup className="mb-4">
                  <Label>End</Label>
                  <InputGroup>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="End"
                      value={filter.end}
                      onChange={(dates) => {
                        setFilter({
                          ...filter,
                          end: format(new Date(dates[0]), "yyyy-MM-dd"),
                        });
                        if (filter.start) {
                          setReload(!reload);
                        }
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
          {loading ? (
            <h4 style={{ textAlign: "center" }}>Loading...</h4>
          ) : (
            <Row>
              <Col md={3}>
                <Card className="mini-stat bg-primary text-white">
                  <CardBody>
                    <Col>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="float-start mini-stat-img"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <i className="fas fa-dollar-sign fs-1" />
                        </div>
                      </Row>
                      <br />
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <h4
                          className="text-uppercase mt-0 text-white-50"
                          style={{ textAlign: "center" }}
                        >
                          Order Revenue
                        </h4>
                      </Row>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <h1
                          className="fw-medium"
                          style={{ textAlign: "center" }}
                        >
                          <CountUp
                            duration={1}
                            start={0}
                            end={orders}
                            suffix=" EGP"
                          />
                          {/* {orders.toLocaleString(
                                                    undefined,
                                                    {
                                                        maximumFractionDigits: 2,
                                                    }
                                                )} */}
                        </h1>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              <Col md={9}>
                <Box
                  sx={{
                    padding: 4,
                    backgroundColor: "#fff",
                    borderRadius: 2,
                    boxShadow: 3,
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Sales Dashboard
                  </Typography>

                  {/* Filters */}
                  <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel id="year-label">Year</InputLabel>
                        <Select
                          labelId="year-label"
                          value={year}
                          onChange={(e) => setYear(e.target.value)}
                        >
                          {years.map((y) => (
                            <MenuItem key={y} value={y}>
                              {y || "All"}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel id="month-label">Month</InputLabel>
                        <Select
                          labelId="month-label"
                          value={month}
                          onChange={(e) => setMonth(e.target.value)}
                        >
                          {months.map((m) => (
                            <MenuItem key={m} value={m}>
                              {m ? monthNames[m - 1] : "All"}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel id="product-label">Product</InputLabel>
                        <Select
                          labelId="product-label"
                          value={product}
                          onChange={(e) => setProduct(e.target.value)}
                        >
                          {products.map((product, id) => (
                            <MenuItem key={id} value={product._id}>
                              {product.name|| "All"}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Chart */}
                  {filteredData?.length > 0 ? (
                    <ResponsiveContainer width="100%" height={500}>
                      <BarChart
                        data={filteredData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="product" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar
                          dataKey="totalViewsCount"
                          fill="#1976d2"
                          name="Total Views"
                        />
                        <Bar
                          dataKey="totalCartCount"
                          fill="#ff9800"
                          name="Total Carts"
                        />
                    
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <Typography variant="h6" align="center">
                      No data available for the selected filters.
                    </Typography>
                  )}
                </Box>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Dashboard);
