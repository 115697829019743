/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";

import "chartist/dist/scss/chartist.scss";
import OrderDetails from "./OrderDetails.js";

//i18n
import { withTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import axios from "axios/axios.js";
import { MDBDataTable } from "mdbreact";
import { format } from "date-fns";

const Orders = () => {
  const columns = [
    {
      label: "ID",
      field: "_id",
      sort: "asc",
    },
    {
      label: "User",
      field: "user",
      sort: "asc",
    },
    // {
    //     label: "Address",
    //     field: "address",
    //     sort: "asc",
    // },
    {
      label: "Phone",
      field: "phone",
      sort: "asc",
    },
    // {
    //     label: "Products",
    //     field: "productsNum",
    //     sort: "asc",
    // },
    {
      label: "Total",
      field: "total",
      sort: "asc",
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
    },
  ];
  const orderInitial = {
    titleEnglish: "",
    descriptionEnglish: "",
    bodyEnglish: "",
    titleArabic: "",
    descriptionArabic: "",
    bodyArabic: "",
    img: null,
  };
  const [menu, setMenu] = useState(false);
  const [reload, setReload] = useState(false);
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState(orderInitial);
  const [selectedOrder, setSelectedOrder] = useState();
  // document.title = "Orders"

  useEffect(() => {
    axios
      .get("orders/all", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setOrders(res.data.orders);
      })
      .catch((e) => {});
  }, [reload]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Orders</h5>
              </Col>
            </Row>
          </div>

          <Row className="mb-4">
            <Col>
              <Accordion expanded={menu} style={{ boxShadow: "none" }}>
                <AccordionSummary
                  style={{ backgroundColor: "#E9ECEF" }}
                  onClick={() => {
                    if (selectedOrder) setMenu(!menu);
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h6
                    style={{
                      fontWeight: "bold",
                      color: "#5B626B",
                    }}
                  >
                    Order Details
                  </h6>
                </AccordionSummary>
                <AccordionDetails>
                  {selectedOrder && (
                    <OrderDetails
                      selectedOrder={selectedOrder}
                      setSelectedOrder={setSelectedOrder}
                      setReload={setReload}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Orders List</h6>
                </CardHeader>
                <CardBody>
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: orders.map((one) => {
                        return {
                          ...one,
                          user: one?.user
                            ? one?.user.firstName + " " + one?.user.lastName
                            : one?.firstName + " " + one?.lastName,
                          // address: one.address
                          //     ? one.address
                          //           .apartment +
                          //       ", " +
                          //       one.address.street +
                          //       ", " +
                          //       one.address.city
                          //     : "",
                          userName: one?.user
                            ? one?.user.firstName + " " + one?.user.lastName
                            : one?.firstName + " " + one?.lastName,
                          // productsNum: one.products
                          //     ? one.products.length
                          //     : 0,
                          total: one.total ? one.total.toFixed(2) + " EGP" : "",
                          date: one.createdAt
                            ? format(new Date(one.createdAt), "yyyy-MM-dd")
                            : "",
                          clickEvent: () => {
                            if (
                              selectedOrder &&
                              selectedOrder._id === one._id
                            ) {
                              setSelectedOrder(null);
                              setMenu(false);
                            } else {
                              setSelectedOrder(one);
                              setMenu(true);
                            }
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          },
                        };
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Orders.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Orders);
